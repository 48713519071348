import React from "react";

import { HasuraConSEO } from "../../../components/hasuracon2021/HasuraConSEO";
import Layout from "../../../components/hasuracon2021/Layout";
import Jobs from "../../../components/hasuracon2021/Jobs";
import HeroOverviewPattern from "../../../components/hasuracon2021/images/hero-overview-pattern.png";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/jobs/";

const JobsComp = props => (
  <Layout
    location={props.location}
    heroGraphic={() => (
      <img
        className="heroBanRightPattern"
        loading="lazy"
        src={HeroOverviewPattern}
        alt="Speakers Graphic"
      />
    )}
  >
    <HasuraConSEO canonicalLink={canonicalUrl} />
    <Jobs />
  </Layout>
);

export default JobsComp;
