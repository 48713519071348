import React from "react";

import yugabyteImg from "./images/yugabyte-dark.png";
import timescaleImg from "./images/timescale_logo.svg";
import hasuraLogo from "./images/hasura_jobs_logo.svg";
import "./styles.scss";

const Jobs = () => (
  <section className="sectionWrapper removePaddTop jobs-section-wrapper">
    <div className="cocWrapper">
      <h1 className="title">
        Check out these Job Offers
        <br /> from our Sponsors
      </h1>
      <div className="articleDesc">
        <img src={yugabyteImg} id="yugabyte-img" alt="Yugabyte" />
        <h2 className="jobTitle">Developer Advocate</h2>
        <div>
          We are looking for a developer advocate who can become the voice of our primary users, the
          app builders. The ideal candidate will be very interested in learning new technologies and
          frameworks, experiment with these and build sample applications or integrations, attend
          and present these at various conferences, build real world sample applications and help
          with documentation.
        </div>
        <div className="applyBtn">
          <a target="_blank" rel="noreferrer" href="https://www.yugabyte.com/careers/">
            Apply
          </a>
        </div>
      </div>
      <hr />
      <div className="articleDesc">
        <img src={timescaleImg} id="timescale-img" alt="Timescale" />
        <h2 class="jobTitle">Core Database Developer</h2>
        <div>
          You will act as both architect and programmer, designing, coding, and testing the core of
          our PostgreSQL-based time-series database. Our focus is on building a scalable, reliable,
          robust, usable, secure, performant database. You should have strong systems-building
          experience and expertise in C, understand both query optimization and database systems,
          and ideally already be a PostgreSQL expert (or show an interest and quick aptitude to
          become a PostgreSQL expert). And the future of managing and making sense of the deluge of
          time-series data should excite you. Timescale is a 100% remote organization, with
          engineers throughout the world; this is a full-time position.
        </div>
        <div className="applyBtn">
          <a target="_blank" rel="noreferrer" href="https://www.timescale.com/careers/">
            Apply
          </a>
        </div>
        <h2 className="jobTitle">Software Engineer</h2>
        <div>
          We're looking for experienced software engineers to help us build and maintain our managed
          TimescaleDB cloud services. You will have the opportunity to work with a close-knit team,
          developing Golang services that power our users’ TimescaleDB experience. You will design
          and architect features and services from the ground up and have ownership over them from
          the design phase through deployment to production. Timescale is a 100% remote
          organization, with engineers throughout the world; this is a full-time position.
        </div>
        <div className="applyBtn">
          <a target="_blank" rel="noreferrer" href="https://www.timescale.com/careers/">
            Apply
          </a>
        </div>
      </div>
      <hr />
      <div className="articleDesc hasura-jobs-listing">
        <img src={hasuraLogo} id="hasura-jobs-logo" alt="Hasura" />
        <h2 className="jobTitle" style={{ color: "#fff" }}>
          We have the following job openings -
        </h2>
        <h2 className="jobTitle">&#8226;&nbsp; Analytics and Data Engineer (Remote Worldwide)</h2>
        <h2 className="jobTitle">
          &#8226;&nbsp; Fullstack Engineer - Hasura Cloud (Remote Worldwide)
        </h2>
        <h2 className="jobTitle">
          &#8226;&nbsp; Manager - Application Interfaces (Remote Worldwide)
        </h2>
        <h2 className="jobTitle">
          &#8226;&nbsp; Manager - Site Reliability Engineering (SRE) Team (Remote Worldwide)
        </h2>
        <h2 className="jobTitle">&#8226;&nbsp; Senior Backend (Go) Engineer (Remote Worldwide)</h2>
        <h2 className="jobTitle">
          &#8226;&nbsp; Senior DevOps / Site Reliability Engineer (SRE) (Remote US, India)
        </h2>
        <h2 className="jobTitle">&#8226;&nbsp; Senior Haskell Engineer (Remote Worldwide)</h2>
        <h2 className="jobTitle">&#8226;&nbsp; Solutions Engineer (Remote US, India)</h2>
        <h2 className="jobTitle">
          &#8226;&nbsp; Support Engineer (Hasura Cloud) (Remote EU, India)
        </h2>
        <h2 className="jobTitle">
          &#8226;&nbsp; Enterprise Technical Product Marketer (Remote Worldwide)
        </h2>
        <div>
          P.S - If none of the above job openings match your profile, kindly feel free to fill in an
          open application with us and we will get back to you once we have a suitable job opening
          matching your skillset.
        </div>
        <div className="applyBtn">
          <a target="_blank" rel="noreferrer" href="https://hasura.io/careers/">
            Apply
          </a>
        </div>
      </div>
    </div>
  </section>
);

export default Jobs;
